<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col"><Topbar ref="con1" @reload="reload" /></div>
    </div>
    <div class="row">
      <div class="col"><Bottombar ref="con2" /></div>
    </div>
  </div>        
</template>
<script>
import { mapState } from "vuex";
import Topbar from "./uscontrol/Verify_topbar.vue"
import Bottombar from "./uscontrol/Verify_bottomtbar.vue"


export default {
  name: 'Verify_actlist',   
  components: {
    Topbar,
    Bottombar,
  },
  data() {
    return {
      data: [],
    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
  },
  mounted() {

  },
  methods: {
    reload(){
      this.$refs["con1"].reload()
      this.$refs["con2"].reload()
    }
  },
};
</script>